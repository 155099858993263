.verse-container {
    text-align: center;
    padding: 20px;
    max-width: 600px;
    margin: auto;
    font-family: "Arial", sans-serif;
  }
  
  .verse-text {
    font-size: 20px;
    font-style: italic;
    margin-bottom: 20px;
  }
  
  .fetch-button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .fetch-button:hover {
    background-color: #45a049;
  }
  