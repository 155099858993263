@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&family=Sarabun:wght@200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Spectral:wght@600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    
}
body{
   
    /* background: rgb(106,127,235);
    background: linear-gradient(90deg, rgb(138, 155, 243) 17%, rgba(215,128,128,1) 46%, rgba(142,156,230,1) 79%); */
    background-color: #edeaed;
    color: black;
    line-height: 1.5;
    font-size: 100%;
    text-align: center;
}
.mainApp{
    font-size: 1rem;
}
p,a,li,span{
    line-height: 2;
    font-family: 'Spectral', serif;
}
h1{
    font-family: 'Rubik', sans-serif;
}
h2,h3,h4,h5{
    font-family: 'Rubik', sans-serif;
    font-family: 'Sarabun', sans-serif;
}
ul li{
    list-style: none;
}
/* filepath: /c:/Users/Ainoo Frank/Documents/christmiracle/client/src/App.css */
.dateTimeDisplay {
    text-align: center;
    font-size: 1.2em;
    margin: 1.5em 0em;
  }

@media(max-width: 700px){
    body{
        font-size: 0.7rem;
    }
    h2{
        font-size: 0.8rem;
    }
    h1{
        font-size: 1rem;
    }
    h3{
        font-size: 0.9rem;
    }
    h4{
        font-size: 0.9rem;
    }
}











                